@font-face {
  font-family: Hanken Sans;
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/HankenSans-Bold.eot');
  src: url('../fonts/HankenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HankenSans-Bold.woff2') format('woff2'),
    url('../fonts/HankenSans-Bold.woff') format('woff'),
    url('../fonts/HankenSans-Bold.ttf') format('truetype'),
    url('../fonts/HankenSans-Bold.svg#hankensans') format('svg');
}

@font-face {
  font-family: Hanken Sans;
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/HankenSans-Medium.eot');
  src: url('../fonts/HankenSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HankenSans-Medium.woff2') format('woff2'),
    url('../fonts/HankenSans-Medium.woff') format('woff'),
    url('../fonts/HankenSans-Medium.ttf') format('truetype'),
    url('../fonts/HankenSans-Medium.svg#hankensans') format('svg');
}

@font-face {
  font-family: Hanken Sans;
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/HankenSans-Regular.eot');
  src: url('../fonts/HankenSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HankenSans-Regular.woff2') format('woff2'),
    url('../fonts/HankenSans-Regular.woff') format('woff'),
    url('../fonts/HankenSans-Regular.ttf') format('truetype'),
    url('../fonts/HankenSans-Regular.svg#hankensans') format('svg');
}
